// import { RiArrowRightSLine, RiFacebookFill, RiTwitterFill, RiInstagramLine } from "react-icons/ri";
import { RiFacebookFill, RiTwitterFill, RiInstagramLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  return (
    <>
      {" "}
      {location.pathname !== "/InSession" &&
        location.pathname !== "/Admin" &&
        location.pathname !== "/AdminCompany" &&
        location.pathname !== "/Owner" && (
          <footer className="Footer backColLinksAndTitlesBlack">
            <div className="mY30 w80 mXAuto footerMainSubb1">
              {/*<div className="verticallyAlignMidStretch footerMainSubb4">
                        <div className="verticallyAlignMidStretch mRightAuto">
                            <div className="mRight160 footerMainSubb2">
                                <div className="footerMainSubb5">
                                    <h5 className="P1regular textAlignLeft mBottom20 colOffWhite w180 mBottom40">Subscribe to our newsletter</h5>
                                    <form className="w340 formForFooterEmail verticallyAlignMidStretch mRightAuto borderBottomForFooterForm">
                                        <input type="email" placeholder="Email address" className="borderNone backColTransparent textAlignLeft colOffWhite"/>
                                        <button type="submit" className="backgroundGradientForButtons borderNone borderRadius12Top verticallyAlignMid">
                                            <RiArrowRightSLine size="1.5em" className="colOffWhite mY16 mX16" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="verticallyAlignMidStretch footerMainSubb6 w600 mXAuto">
                            <div className="mRight160 footerMainSubb3">
                                <p className="textLinearGradieant Pregular textAlignLeft mBottom16">Services</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Sessions</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Courses</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Mentors</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Therapy</p>
                            </div>
                            <div className="mRight160 footerMainSubb3">
                                <p className="textLinearGradieant Pregular textAlignLeft  mBottom16">About</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Our Story</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Benefits</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Team</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Careers</p>
                            </div>
                            <div>
                                <p className="textLinearGradieant Pregular textAlignLeft  mBottom16">Help</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">FAQs</p>
                                <p className="textAlignLeft colOffWhite cursorPointer mBottom16">Contact Us</p>
                            </div>
                        </div>
                    </div>*/}
              <div className="verticallyAlignMidJustConStart footerMainSubb7 mY30">
                {/*<div className="verticallyAlignMidJustConStart footerMainSubb71">
                            <p className="textAlignLeft colOffWhite mRight50 footerMainSubb71p cursorPointer">Terms and Conditions</p>
                            <p className="textAlignLeft colOffWhite cursorPointer">Privacy Policy</p>
                        </div>*/}
                <div className="verticallyAlignMid mLeftAuto footerMainSubb72">
                  <RiFacebookFill
                    size="1.5em"
                    className="colOffWhite mRight40 cursorPointer"
                  />
                  <RiTwitterFill
                    size="1.5em"
                    className="colOffWhite mRight40 cursorPointer"
                  />
                  <RiInstagramLine
                    size="1.5em"
                    className="colOffWhite mRight40 cursorPointer"
                  />
                </div>
              </div>
            </div>
          </footer>
        )}
    </>
  );
}

export default Footer;
