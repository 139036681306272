import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import * as actionType from "../../constants/actionTypes";
// import PropTypes from 'prop-types';
import { Navbar, Nav } from "react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBell } from '@fortawesome/free-regular-svg-icons';
import { BsBell } from "react-icons/bs";
import { socket } from "./../../actions/socket";
import { BsSuitHeart } from "react-icons/bs";

import { getNotifications } from "../../api/index.js";

const NavBar = (props) => {
  // console.log("props", props);
  const company = props.company;

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [profileClick, setProfileClick] = useState(false);
  const [viewLinks, setViewLinks] = useState(false);
  // const [redDot, setRedDot]               = useState(props.redDot);
  const [selected, setSelected] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    // console.log("emitting logout");
    socket.emit("logout");
    dispatch({ type: actionType.LOGOUT });

    setProfileClick(false);

    // navigate(to, { state={}, replace=false })
    navigate("../LogIn");

    setUser(null);
  };

  useEffect(() => {
    (async () => {
      const queryString = `?userID=${props.user._id}&seen=false`;

      if (props.user) {
        const { data } = await getNotifications(queryString);

        if (data.results) {
          props.parentEvent(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    setUser(JSON.parse(localStorage.getItem("profile")));

    if (
      (JSON.parse(localStorage.getItem("profile"))?.data?.user.role ===
        "mentor" &&
        JSON.parse(localStorage.getItem("profile"))?.data?.user.mentorOnboarding
          .activeMentor) ||
      // JSON.parse(localStorage.getItem("profile"))?.data?.user.clientOnboarding
      //   .paymentMethodCompleted
      JSON.parse(localStorage.getItem("profile"))?.data?.user.role === "client"
    ) {
      setViewLinks(true);
    }
  }, [navigate, user?.token]);

  function goToHome() {
    setProfileClick(false);

    if (!user?.data?.user) {
      navigate("/LogIn");
      return;
    }

    if (user.data.user.role === "mentor") {
      navigate("/OnboardingMentor12WelcomePage");
    } else if (user.data.user.role === "client") {
      navigate("/WhatKindOfMentor");
    }
  }

  function goToNotifications() {
    setProfileClick(false);
    props.parentEvent(false);

    navigate("/Notifications");
  }

  function goToSchedule() {
    setProfileClick(false);
    navigate("/Schedule");
  }

  function goToProfile() {
    setProfileClick(false);
    navigate("/MentorHome2SettingsPanel");
  }

  // function goToVIdeoCall() {
  //   setProfileClick(false);
  //   navigate("/VideoCall");
  // }

  function goToMessages() {
    setProfileClick(false);
    navigate("/Messages");
  }

  function goToNotes() {
    setProfileClick(false);
    navigate("/ClientNotes");
  }

  function goToFavourites() {
    navigate("/FavoriteMentors");
  }

  function goToHelp() {
    setProfileClick(false);
    navigate("/ClientSettingsPanelContactSupport");
  }

  function setAvailbleNow(e) {
    setSelected((selected) => !selected);

    if (selected) {
      socket.emit("joinAvailableRoom");
    } else {
      socket.emit("leaveAvailableRoom");
    }
  }

  window.addEventListener("click", function (e) {
    if (
      document.getElementsByClassName("usersDropDownMenu")[0] &&
      !document
        .getElementsByClassName("usersDropDownMenu")[0]
        .contains(e.target)
    ) {
      setProfileClick(false);
    }
  });

  function getInitials(name) {
    if (name) {
      let words = name.split(" ");
      let initials = words[0].charAt(0).toUpperCase();

      if (words.length > 1) {
        initials += words[1].charAt(0).toUpperCase();
      }

      return initials;
    }
  }

  return (
    <>
      {location.pathname.startsWith("/Admin") ||
      location.pathname.startsWith("/Owner") ? (
        <Nav.Link
          onClick={() => {
            setExpanded(false);
            logout();
          }}
          className="p0 colLinksAndTitlesBlack Pmedium mLeftAuto p-4 cursorPointer"
        >
          Log Out
        </Nav.Link>
      ) : (
        <div className="backColOffWhite boxShadowForSearchLanding mBottom4">
          {user?.data?.user ? (
            <Navbar
              expanded={expanded}
              expand="lg"
              className="verticallyAlignMidJusStart pY10 w90 mXAuto posRel"
            >
              <div className="posAbs navForProfileSwitchBell verticallyAlignMidJustConStart">
                {viewLinks ? (
                  <>
                    {props.user.role === "mentor" && (
                      <div className="onOffSwitch posRel verticallyAlignMid mLeftAuto mRight10">
                        <input
                          checked={selected}
                          onChange={(e) => setAvailbleNow(e)}
                          className="onOffSwitchInput"
                          type="checkbox"
                        />
                        <span className="slider round"></span>
                      </div>
                    )}
                    <Nav.Link
                      className={`verticallyAlignMid p0 ${
                        props.user.role === "client" ? "mLeftAuto" : ""
                      } mRight12 posRel`}
                    >
                      {props.user.role === "client" && (
                        <BsSuitHeart
                          onClick={goToFavourites}
                          size="1.2em"
                          className="p0 mRight10 colLinksAndTitlesBlack cursorPointer"
                        />
                      )}
                      <BsBell
                        onClick={goToNotifications}
                        size="1.2em"
                        className="p0 colLinksAndTitlesBlack cursorPointer"
                      />
                      {props.redDot && (
                        <div
                          onClick={goToNotifications}
                          className="posAbs redDotForNotification borderRadius50 backColRed"
                        >
                          {/*********** RED DOT ************/}
                        </div>
                      )}
                    </Nav.Link>
                    <Nav.Link className="usersDropDownMenu p0 disFlex posRel">
                      <div className="verticallyAlignMid cursorPointer">
                        {props.user.photo && props.user.photo !== "" ? (
                          <img
                            onClick={() => setProfileClick(!profileClick)}
                            alt="Profile"
                            className="borderRadius50 mainNavBarOriginalImg"
                            src={props.user.photo}
                          />
                        ) : (
                          <div
                            onClick={() => setProfileClick(!profileClick)}
                            className="userInitialsSmall"
                          >
                            <p>{getInitials(props.user.fullName)}</p>
                          </div>
                        )}
                        {profileClick && (
                          <div className="posAbs dropDownForUserIcon boxShadowForSearchLanding backColWhite">
                            <div className="mX20 mY30">
                              <p
                                onClick={goToProfile}
                                className="colLinksAndTitlesBlack Pmedium cursorPointer textAlignLeft"
                              >
                                Profile
                              </p>
                              <div className="borderBottomForButtonsGray2 mY8"></div>
                              {/*<p onClick={goToVIdeoCall} className="colLinksAndTitlesBlack Pmedium cursorPointer textAlignLeft">VideoCall</p>
                                                    <div className="borderBottomForButtonsGray2 mY8"></div>*/}
                              <p
                                onClick={goToMessages}
                                className="colLinksAndTitlesBlack Pmedium cursorPointer textAlignLeft"
                              >
                                Messages
                              </p>
                              <div className="borderBottomForButtonsGray2 mY8"></div>
                              <p
                                onClick={goToNotes}
                                className="colLinksAndTitlesBlack Pmedium cursorPointer textAlignLeft"
                              >
                                My Notes
                              </p>
                              {location.pathname !== "/InSession" && (
                                <>
                                  <div className="borderBottomForButtonsGray2 mY8"></div>
                                  <p
                                    onClick={() => {
                                      props.addNoteEvent(true);
                                      setProfileClick(false);
                                    }}
                                    className="colLinksAndTitlesBlack Pmedium cursorPointer textAlignLeft"
                                  >
                                    Add Note
                                  </p>
                                </>
                              )}
                              <div className="borderBottomForButtonsGray2 mY8"></div>
                              <p
                                onClick={logout}
                                className="colLinksAndTitlesBlack Pmedium cursorPointer textAlignLeft"
                              >
                                Log Out
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Nav.Link>
                  </>
                ) : (
                  // <Nav.Link onClick={logout} className="p0 colLinksAndTitlesBlack Pmedium mLeftAuto cursorPointer">Log Out</Nav.Link>
                  ""
                )}
              </div>
              <Navbar.Brand
                onClick={goToHome}
                className="logoMain cursorPointer"
              >
                <div className="verticallyAlignMid">
                  {company?.logo && company.logo !== "" ? (
                    <img
                      alt="Company"
                      className="logoNavBarImage"
                      src={company.logo}
                    />
                  ) : (
                    <img
                      alt="Company"
                      className="logoNavBarImage"
                      src="https://i.ibb.co/fD1yxzd/img.png"
                    />
                  )}
                </div>
              </Navbar.Brand>
              <Navbar.Toggle
                onClick={() => setExpanded(expanded ? false : "expanded")}
                aria-controls="basic-navbar-nav"
              />
              <Navbar.Collapse id="basic-navbar-nav">
                {viewLinks ? (
                  <Nav className="mr-auto verticallyAlignMidJusStart w100">
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        goToHome();
                      }}
                      className="p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        goToSchedule();
                      }}
                      className="p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                    >
                      Schedule
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        goToHelp();
                      }}
                      className="p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                    >
                      Help
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        goToProfile();
                      }}
                      className="hideNavItemForLargeVP p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                    >
                      Profile
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        goToMessages();
                      }}
                      className="hideNavItemForLargeVP p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                    >
                      Messages
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        goToNotes();
                      }}
                      className="hideNavItemForLargeVP p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                    >
                      My Notes
                    </Nav.Link>
                    {location.pathname !== "/InSession" && (
                      <>
                        <Nav.Link
                          onClick={() => {
                            setExpanded(false);
                            props.addNoteEvent(true);
                            setProfileClick(false);
                          }}
                          className="hideNavItemForLargeVP p0 colLinksAndTitlesBlack Pmedium mRight20LargeVP cursorPointer verticallyAlignMid"
                        >
                          Add Note
                        </Nav.Link>
                      </>
                    )}
                    <Nav.Link
                      onClick={() => {
                        setExpanded(false);
                        logout();
                      }}
                      className="verticallyAlignMid hideNavItemForLargeVP p0 colLinksAndTitlesBlack Pmedium cursorPointer"
                    >
                      Log Out
                    </Nav.Link>
                  </Nav>
                ) : (
                  <Nav.Link
                    onClick={() => {
                      setExpanded(false);
                      logout();
                    }}
                    className="p0 colLinksAndTitlesBlack Pmedium mLeftAuto cursorPointer"
                  >
                    Log Out
                  </Nav.Link>
                )}
              </Navbar.Collapse>
            </Navbar>
          ) : (
            <Navbar
              sticky="top"
              className="verticallyAlignMidJusStart pY10 w90 mXAuto"
            >
              <Navbar.Brand
                onClick={goToHome}
                className="mRight160 cursorPointer"
              >
                <div className="verticallyAlignMid">
                  {company?.logo && company.logo !== "" ? (
                    <img
                      alt="Company"
                      className="logoNavBarImage"
                      src={company.logo}
                    />
                  ) : (
                    <img
                      alt="Company"
                      className="logoNavBarImage"
                      src="https://i.ibb.co/fD1yxzd/img.png"
                    />
                  )}
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Navbar>
          )}
        </div>
      )}
    </>
  );
};

function getUser(state) {
  return {
    user: state.auth.authData?.data?.user || "",
    company: state.auth.authData?.company,
  };
}

export default connect(getUser)(NavBar);
