import ViewRescheduleSessionCardType2 from "../../components/ViewRescheduleSessionCardType2/ViewRescheduleSessionCardType2";
import MentorHomeLastSessionsComponent from "../../components/MentorHomeLastSessionsComponent/MentorHomeLastSessionsComponent";
import { RiStarFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReviews } from "../../api/index.js";
import Moment from "moment";
import { connect } from "react-redux";

function MentorHome1({ sessions, mentor, company }) {
  // console.log(mentor)

  const navigate = useNavigate();

  const initialPerformance = {
    earned: 0,
    minutes: 0,
    clients: 0,
    sessions: 0,
  };

  const initialChartData = {
    chartTime: "allTime",
    chartSession: "",
    pickedContext: "hoursHeld",
  };

  const initialChart = {
    Monday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
    Tuesday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
    Wednesday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
    Thursday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
    Friday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
    Saturday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
    Sunday: {
      earned: 0,
      minutes: 0,
      clients: 0,
      sessions: 0,
    },
  };

  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [lastSessions, setLastSessions] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [performance, setPerformance] = useState(initialPerformance);
  const [chartData, setChartData] = useState(initialChartData);

  const [firstLine, setFirstLine] = useState(0);
  const [secondLine, setSecondLine] = useState(0);
  const [thirdLine, setThirdLine] = useState(0);
  const [fourthLine, setFourthLine] = useState(0);
  const [fifthLine, setFifthLine] = useState(0);
  const [sixthLine, setSixthLine] = useState(0);
  const [seventhLine, setSeventhLine] = useState(0);

  const [y1, setY1] = useState(80);
  const [y2, setY2] = useState(60);
  const [y3, setY3] = useState(40);
  const [y4, setY4] = useState(20);

  const [perfTotalEarned, setPerfTotalEarned] = useState(true);
  const [perfNetEarned, setPerfNetEarned] = useState(false);
  const [perfHoursHeld, setPerfHoursHeld] = useState(false);
  const [perfClients, setPerfClients] = useState(false);
  const [perfSessions, setPerfSessions] = useState(false);

  const handleChange = (e) => {
    setChartData({ ...chartData, [e.target.name]: e.target.value });
  };

  const pickTotalEarned = () => {
    if (!perfTotalEarned) {
      setPerfTotalEarned(true);
      if (perfNetEarned) {
        setPerfNetEarned(false);
      }
      if (perfHoursHeld) {
        setPerfHoursHeld(false);
      }
      if (perfClients) {
        setPerfClients(false);
      }
      if (perfSessions) {
        setPerfSessions(false);
      }
      setChartData({ ...chartData, pickedContext: "totalEarned" });
    }
  };

  const pickNetEarned = () => {
    if (!perfNetEarned) {
      setPerfNetEarned(true);
      if (perfTotalEarned) {
        setPerfTotalEarned(false);
      }
      if (perfHoursHeld) {
        setPerfHoursHeld(false);
      }
      if (perfClients) {
        setPerfClients(false);
      }
      if (perfSessions) {
        setPerfSessions(false);
      }
      setChartData({ ...chartData, pickedContext: "totalEarned" });
    }
  };

  const pickHoursHeld = () => {
    if (!perfHoursHeld) {
      setPerfHoursHeld(true);
      if (perfTotalEarned) {
        setPerfTotalEarned(false);
      }
      if (perfNetEarned) {
        setPerfNetEarned(false);
      }
      if (perfClients) {
        setPerfClients(false);
      }
      if (perfSessions) {
        setPerfSessions(false);
      }
      setChartData({ ...chartData, pickedContext: "hoursHeld" });
    }
  };

  const pickClients = () => {
    if (!perfClients) {
      setPerfClients(true);
      if (perfTotalEarned) {
        setPerfTotalEarned(false);
      }
      if (perfNetEarned) {
        setPerfNetEarned(false);
      }
      if (perfHoursHeld) {
        setPerfHoursHeld(false);
      }
      if (perfSessions) {
        setPerfSessions(false);
      }
      setChartData({ ...chartData, pickedContext: "clients" });
    }
  };

  const pickSessions = () => {
    if (!perfSessions) {
      setPerfSessions(true);
      if (perfTotalEarned) {
        setPerfTotalEarned(false);
      }
      if (perfNetEarned) {
        setPerfNetEarned(false);
      }
      if (perfHoursHeld) {
        setPerfHoursHeld(false);
      }
      if (perfClients) {
        setPerfClients(false);
      }
      setChartData({ ...chartData, pickedContext: "sessions" });
    }
  };

  useEffect(() => {
    async function getResults() {
      const queryString = `?populate=clientID&mentorID=${mentor._id}&sort=-createdAt`;

      const { data } = await getReviews(queryString);

      // console.log(data.data.reviews)

      let rating = 0;

      if (data.data.reviews && data.data.reviews.length > 0) {
        data.data.reviews.forEach((el) => {
          rating += parseInt(el.rating);
        });

        rating = Math.round((rating / data.data.reviews.length) * 10) / 10;
      }

      // console.log(sessions)

      setAvgRating(rating);
      setReviews(data.data.reviews);

      const upcoming = sessions.filter((el) => el.status === "scheduled");
      const last = sessions.filter((el) => el.status === "completed");

      // console.log(upcoming)
      // console.log(last)
      setUpcomingSessions(upcoming);
      setLastSessions(last);

      updatePerformance();

      function updatePerformance() {
        let obj = initialPerformance;

        let clients = [];

        sessions.forEach((el) => {
          obj.earned += el.actualPrice;
          obj.minutes += parseInt(el.serviceID.duration);

          if (clients.indexOf(el.clientID._id) < 0) {
            clients.push(el.clientID._id);
            obj.clients++;
          }

          obj.sessions++;
        });

        // console.log(obj)
        // setPerformance(prevState => ({...prevState, obj}));
      }
    }

    getResults();
  }, [sessions]);

  useEffect(() => {
    // console.log(chartData);

    function updateChart() {
      let obj = initialChart;

      let clients = [];
      let clientsDays = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      };
      // let clientsTue = [];
      // let clientsWed = [];
      // let clientsThu = [];
      // let clientsFri = [];
      // let clientsSat = [];
      // let clientsSun = [];
      let firstDate = new Date();
      let maxEarnedInDay = 0;
      let maxMinutesInDay = 0;
      // let maxClientsInDay = 0;
      // let maxSessionsInDay = 0;

      sessions.forEach((el) => {
        let date = new Date(el.date);
        let day = date.toLocaleString("en-us", { weekday: "long" });

        // console.log(obj[day]);
        if (date < firstDate) {
          firstDate = date;
        }

        obj[day]["earned"] += el.actualPrice;
        obj[day]["minutes"] += parseInt(el.serviceID.duration);
        obj[day]["sessions"]++;

        if (obj[day]["minutes"] > maxMinutesInDay) {
          maxMinutesInDay = obj[day]["minutes"]; //This will be the value to set at highest value on y axis
        }
        // if(obj[day]["minutes"] > maxClientsInDay) {
        // 	maxClientsInDay = obj[day]["minutes"]; //This will be the value to set at highest value on y axis
        // }
        if (obj[day]["earned"] > maxEarnedInDay) {
          maxEarnedInDay = obj[day]["earned"]; //This will be the value to set at highest value on y axis
        }

        if (clients.indexOf(el.clientID._id) < 0) {
          clients.push(el.clientID._id);
          // obj[day]["clients"]++;
        }
        if (clientsDays[day].indexOf(el.clientID._id) < 0) {
          clientsDays[day].push(el.clientID._id);
          obj[day]["clients"]++;
        }
      });

      let numberOfDays = Math.ceil(
        (new Date() - firstDate) / (1000 * 3600 * 24)
      );
      if (numberOfDays < 1) {
        numberOfDays = 1;
      }

      let yAxisMax = 0;

      let timePeriod = numberOfDays;
      if (chartData.chartTime === "allTime") {
        timePeriod = numberOfDays;
      }
      if (chartData.chartTime === "monthly") {
        timePeriod = 30;
      }
      if (chartData.chartTime === "weekly") {
        timePeriod = 7;
      }
      if (chartData.chartTime === "yearly") {
        timePeriod = 365;
      }
      if (chartData.pickedContext === "totalEarned") {
        yAxisMax = ((maxEarnedInDay * 0.95) / numberOfDays) * timePeriod;
        setFirstLine(
          (((obj["Monday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
        setSecondLine(
          (((obj["Tuesday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
        setThirdLine(
          (((obj["Wednesday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
        setFourthLine(
          (((obj["Thursday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
        setFifthLine(
          (((obj["Friday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
        setSixthLine(
          (((obj["Saturday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
        setSeventhLine(
          (((obj["Sunday"]["earned"] / numberOfDays) * timePeriod * 0.95) /
            yAxisMax) *
            80
        );
      }
      if (chartData.pickedContext === "netEarned") {
        yAxisMax = (maxEarnedInDay / numberOfDays) * timePeriod;
        setFirstLine(
          (((obj["Monday"]["earned"] / numberOfDays) * timePeriod) / yAxisMax) *
            80
        );
        setSecondLine(
          (((obj["Tuesday"]["earned"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setThirdLine(
          (((obj["Wednesday"]["earned"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setFourthLine(
          (((obj["Thursday"]["earned"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setFifthLine(
          (((obj["Friday"]["earned"] / numberOfDays) * timePeriod) / yAxisMax) *
            80
        );
        setSixthLine(
          (((obj["Saturday"]["earned"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSeventhLine(
          (((obj["Sunday"]["earned"] / numberOfDays) * timePeriod) / yAxisMax) *
            80
        );
      }
      if (chartData.pickedContext === "hoursHeld") {
        yAxisMax = (maxMinutesInDay / 60 / numberOfDays) * timePeriod;
        setFirstLine(
          (((obj["Monday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
        setSecondLine(
          (((obj["Tuesday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
        setThirdLine(
          (((obj["Wednesday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
        setFourthLine(
          (((obj["Thursday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
        setFifthLine(
          (((obj["Friday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
        setSixthLine(
          (((obj["Saturday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
        setSeventhLine(
          (((obj["Sunday"]["minutes"] / numberOfDays) * timePeriod) /
            60 /
            yAxisMax) *
            80
        );
      }
      if (chartData.pickedContext === "clients") {
        yAxisMax =
          (Math.max(
            obj["Monday"]["clients"],
            obj["Tuesday"]["clients"],
            obj["Wednesday"]["clients"],
            obj["Thursday"]["clients"],
            obj["Friday"]["clients"],
            obj["Saturday"]["clients"],
            obj["Sunday"]["clients"]
          ) /
            numberOfDays) *
          timePeriod;
        setFirstLine(
          (((obj["Monday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSecondLine(
          (((obj["Tuesday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setThirdLine(
          (((obj["Wednesday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setFourthLine(
          (((obj["Thursday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setFifthLine(
          (((obj["Friday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSixthLine(
          (((obj["Saturday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSeventhLine(
          (((obj["Sunday"]["clients"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
      }
      if (chartData.pickedContext === "sessions") {
        yAxisMax =
          (Math.max(
            obj["Monday"]["sessions"],
            obj["Tuesday"]["sessions"],
            obj["Wednesday"]["sessions"],
            obj["Thursday"]["sessions"],
            obj["Friday"]["sessions"],
            obj["Saturday"]["sessions"],
            obj["Sunday"]["sessions"]
          ) /
            numberOfDays) *
          timePeriod;
        setFirstLine(
          (((obj["Monday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSecondLine(
          (((obj["Tuesday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setThirdLine(
          (((obj["Wednesday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setFourthLine(
          (((obj["Thursday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setFifthLine(
          (((obj["Friday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSixthLine(
          (((obj["Saturday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
        setSeventhLine(
          (((obj["Sunday"]["sessions"] / numberOfDays) * timePeriod) /
            yAxisMax) *
            80
        );
      }

      //set y-axis values
      if (yAxisMax > 1000000000) {
        setY1(`${Math.round(yAxisMax / 1000000000)}B`);
      } else if (yAxisMax > 1000000) {
        setY1(`${Math.round(yAxisMax / 1000000)}M`);
      } else if (yAxisMax > 1000) {
        setY1(`${Math.round(yAxisMax / 1000)}K`);
      } else if (yAxisMax > 100) {
        setY1(`${Math.round(yAxisMax / 10) * 10}`);
      } else if (yAxisMax > 10) {
        setY1(`${Math.round(yAxisMax)}`);
      } else {
        setY1(`${Math.round(yAxisMax * 10) / 10}`);
      }

      if ((yAxisMax * 3) / 4 > 1000000000) {
        setY2(`${Math.round((yAxisMax * 3) / 4 / 1000000000)}B`);
      } else if ((yAxisMax * 3) / 4 > 1000000) {
        setY2(`${Math.round((yAxisMax * 3) / 4 / 1000000)}M`);
      } else if ((yAxisMax * 3) / 4 > 1000) {
        setY2(`${Math.round((yAxisMax * 3) / 4 / 1000)}K`);
      } else if ((yAxisMax * 3) / 4 > 100) {
        setY2(`${Math.round((yAxisMax * 3) / 4 / 10) * 10}`);
      } else if ((yAxisMax * 3) / 4 > 10) {
        setY2(`${Math.round((yAxisMax * 3) / 4)}`);
      } else {
        setY2(`${Math.round(((yAxisMax * 3) / 4) * 10) / 10}`);
      }

      if ((yAxisMax * 2) / 4 > 1000000000) {
        setY3(`${Math.round((yAxisMax * 2) / 4 / 1000000000)}B`);
      } else if ((yAxisMax * 2) / 4 > 1000000) {
        setY3(`${Math.round((yAxisMax * 2) / 4 / 1000000)}M`);
      } else if ((yAxisMax * 2) / 4 > 1000) {
        setY3(`${Math.round((yAxisMax * 2) / 4 / 1000)}K`);
      } else if ((yAxisMax * 2) / 4 > 100) {
        setY3(`${Math.round((yAxisMax * 2) / 4 / 10) * 10}`);
      } else if ((yAxisMax * 2) / 4 > 10) {
        setY3(`${Math.round((yAxisMax * 2) / 4)}`);
      } else {
        setY3(`${Math.round(((yAxisMax * 2) / 4) * 10) / 10}`);
      }

      if ((yAxisMax * 1) / 4 > 1000000000) {
        setY4(`${Math.round((yAxisMax * 1) / 4 / 1000000000)}B`);
      } else if ((yAxisMax * 1) / 4 > 1000000) {
        setY4(`${Math.round((yAxisMax * 1) / 4 / 1000000)}M`);
      } else if ((yAxisMax * 1) / 4 > 1000) {
        setY4(`${Math.round((yAxisMax * 1) / 4 / 1000)}K`);
      } else if ((yAxisMax * 1) / 4 > 100) {
        setY4(`${Math.round((yAxisMax * 1) / 4 / 10) * 10}`);
      } else if ((yAxisMax * 1) / 4 > 10) {
        setY4(`${Math.round((yAxisMax * 1) / 4)}`);
      } else {
        setY4(`${Math.round(((yAxisMax * 1) / 4) * 10) / 10}`);
      }

      // console.log('clientsDays', clientsDays);
      // console.log('maxEarnedInDay', maxEarnedInDay)
      // console.log('numberOfDays', numberOfDays)
      // console.log(obj)
      // console.log('firstDate', firstDate)
      setPerformance((prevState) => ({
        earned:
          Math.round(
            ((obj["Monday"]["earned"] +
              obj["Tuesday"]["earned"] +
              obj["Wednesday"]["earned"] +
              obj["Thursday"]["earned"] +
              obj["Friday"]["earned"] +
              obj["Saturday"]["earned"] +
              obj["Sunday"]["earned"]) /
              numberOfDays) *
              timePeriod *
              100
          ) / 100,
        minutes:
          Math.round(
            ((obj["Monday"]["minutes"] +
              obj["Tuesday"]["minutes"] +
              obj["Wednesday"]["minutes"] +
              obj["Thursday"]["minutes"] +
              obj["Friday"]["minutes"] +
              obj["Saturday"]["minutes"] +
              obj["Sunday"]["minutes"]) /
              numberOfDays) *
              timePeriod *
              100
          ) / 100,
        clients:
          Math.round(
            ((obj["Monday"]["clients"] +
              obj["Tuesday"]["clients"] +
              obj["Wednesday"]["clients"] +
              obj["Thursday"]["clients"] +
              obj["Friday"]["clients"] +
              obj["Saturday"]["clients"] +
              obj["Sunday"]["clients"]) /
              numberOfDays) *
              timePeriod *
              100
          ) / 100,
        sessions:
          Math.round(
            ((obj["Monday"]["sessions"] +
              obj["Tuesday"]["sessions"] +
              obj["Wednesday"]["sessions"] +
              obj["Thursday"]["sessions"] +
              obj["Friday"]["sessions"] +
              obj["Saturday"]["sessions"] +
              obj["Sunday"]["sessions"]) /
              numberOfDays) *
              timePeriod *
              100
          ) / 100,
      }));

      // console.log('performance', performance)
    }
    updateChart();
  }, [chartData]);

  function goToSchedule(type) {
    // console.log(type);

    navigate("/Schedule", {
      state: {
        type,
      },
    });
  }

  function goToServices() {
    navigate("/OnboardingMentor13Service");
  }

  function goToSessionSingleView(id, type) {
    navigate("/OnboardingMentor11ClientSingleView", {
      state: {
        id,
        type,
      },
    });
  }

  function getDateForReview(date) {
    let d = Moment(date).format("LLLL");
    return Moment(d).format("MMMM YYYY");
  }

  function goToReviews() {
    navigate("/Reviews", {
      state: {
        mentorID: mentor._id,
        fullPage: true,
      },
    });
  }

  function getInitials(name) {
    let words = name.split(" ");

    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  }

  return (
    <div className="MentorHome1">
      <div className="mY70 w80 mXAuto MentorHome1SubWrapper">
        <h2 className="H1regular textAlignLeft mBottom50">
          Welcome back, <span>{mentor?.fullName?.split(" ")[0]}</span>!
        </h2>
        <div className="verticallyAlignMidJustConStart MentorHome1Subb1 mBottom20">
          <h4 className="H3bold textAlignLeft">Upcoming Sessions</h4>
          <button
            onClick={() => goToSchedule("upcoming")}
            style={{
              backgroundColor: company.whiteLabel.color.buttons.secondary,
            }}
            className="mLeftAuto Pbold colPrimaryGreen borderNone pX30 pY14 borderRadius12"
          >
            View All
          </button>
        </div>
        <div className="w90 verticallyAlignMidJustConStart flexWrap MentorHome1Subb2">
          {/* <div className={(upcomingSessions.length > 3 && "xScrollWrapper") + ` w90 verticallyAlignMidJustConStart flexWrap`}> */}

          {upcomingSessions.length === 0 ? (
            <h3>You don't have upcoming session</h3>
          ) : (
            upcomingSessions.forEach((el, i) => {
              while (i < 2) {
                // for testing
                // while (i < 10) {
                return (
                  <ViewRescheduleSessionCardType2
                    key={i}
                    session={el}
                    type={"Upcoming"}
                    parentEvent={goToSessionSingleView}
                    profilePage={true}
                  />
                );
              }
            })
          )}
        </div>
        <div className="backColElementBackgroundGray borderRadius24 mBottom50 performanceMain">
          <div className="mY50 w90 mXAuto verticallyAlignMidJustConStart performanceMain1">
            <div className="w600 mRightAuto performanceMainSubb1">
              <h6 className="Small-LabelCaps textAlignLeft">
                JAN 2021-JUN 2021
              </h6>
              <div className="verticallyAlignMidJustConStart mBottom12 performanceMainSubb1Head">
                <h4 className="H3bold textAlignLeft mRightAuto">Performance</h4>
                <div className="verticallyAlignMid textAlignLeft borderRadiusForSocialMediaButtons w140 backColWhite mRight4 boxShadowForSearchLanding">
                  <select
                    defaultValue={initialChartData.chartTime}
                    name="chartTime"
                    onChange={(e) => handleChange(e)}
                    className="colPrimaryGreen Small-LabelCaps w104 borderNone mY8 textAlignCenter"
                  >
                    <option value="allTime">All Time</option>
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {/*<div className="verticallyAlignMid textAlignLeft borderRadiusForSocialMediaButtons w140 backColWhite boxShadowForSearchLanding">
					    			<select defaultValue={initialChartData.chartSession} name="chartSession" onChange={(e) => handleChange(e)} className="colPrimaryGreen Small-LabelCaps w104 borderNone mY8 textAlignCenter">
					    				<option>All</option>
					    				<option>Session 1</option>
					    				<option>Session 2...</option>
					    			</select>
					    		</div>*/}
              </div>
              <div className="verticallyAlignMidJustConStart performanceSubbsMain mBottom20">
                <div
                  onClick={pickTotalEarned}
                  className={`earningsTotNet cursorPointer ${
                    perfTotalEarned ? "backColWhite " : ""
                  }borderRadius24 mRight20 boxShadowForSearchLanding`}
                >
                  <h4 className="mTop20 colPrimaryGreen H3bold">
                    $ {Math.round((performance.earned / 100) * 95)}
                  </h4>
                  <p className="mBottom20 colPrimaryGreen Small-Labelnormal">
                    Total Earned
                  </p>
                </div>
                <div
                  onClick={pickNetEarned}
                  className={`earningsTotNet cursorPointer ${
                    perfNetEarned ? "backColWhite " : ""
                  }borderRadius24 boxShadowForSearchLanding`}
                >
                  <h4 className="mTop20 colPrimaryGreen H3bold">
                    $ {performance.earned}
                  </h4>
                  <p className="mBottom20 colPrimaryGreen Small-Labelnormal">
                    Net Earned
                  </p>
                </div>
              </div>
              <div className="verticallyAlignMidJustConStart performanceSubbsMain flexWrap mBottom20">
                <div
                  onClick={pickHoursHeld}
                  className={`earningsHoursClientsSessions cursorPointer ${
                    perfHoursHeld ? "backColWhite " : ""
                  }borderRadius24 mRight30 boxShadowForSearchLanding`}
                >
                  <div className="w80 mXAuto mY14">
                    <p className="Small-LabelCaps textAlignLeft">Hours Held</p>
                    <h3 className="H2medium textAlignLeft">
                      {Math.round(performance.minutes / 60)}
                    </h3>
                  </div>
                </div>
                <div
                  onClick={pickClients}
                  className={`earningsHoursClientsSessions cursorPointer ${
                    perfClients ? "backColWhite " : ""
                  }borderRadius24 mRight30 boxShadowForSearchLanding`}
                >
                  <div className="w80 mXAuto mY14">
                    <p className="Small-LabelCaps textAlignLeft">Clients</p>
                    <h3 className="H2medium textAlignLeft">
                      {performance.clients}
                    </h3>
                  </div>
                </div>
                <div
                  onClick={pickSessions}
                  className={`earningsHoursClientsSessions cursorPointer ${
                    perfSessions ? "backColWhite " : ""
                  }borderRadius24 boxShadowForSearchLanding`}
                >
                  <div className="w80 mXAuto mY14">
                    <p className="Small-LabelCaps textAlignLeft">Sessions</p>
                    <h3 className="H2medium textAlignLeft">
                      {performance.sessions}
                    </h3>
                  </div>
                </div>
              </div>
              <button
                onClick={goToServices}
                style={{
                  color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                  backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                }}
                className="verticallyAlignMid w180 borderRadius12 borderNone Pregular colOffWhite pY14 mRightAuto"
              >
                Manage Services
              </button>
            </div>
            <div className="w440 performanceMainSubb2">
              {/*GRAPHIC DIV */}
              <div className="chartMainWrapper pLeft20">
                <div className="chartTopWrapper">
                  <div className="yLabelsWrapper">
                    <div className="yLabel">
                      <p className="Pregular"> {y1} </p>
                    </div>
                    <div className="yLabel">
                      <p className="Pregular"> {y2} </p>
                    </div>
                    <div className="yLabel">
                      <p className="Pregular"> {y3} </p>
                    </div>
                    <div className="yLabel">
                      <p className="Pregular"> {y4} </p>
                    </div>
                    <div className="yLabel">
                      <p className="Pregular"> 0 </p>
                    </div>
                  </div>
                  <div className="chartContentWrapper">
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${firstLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${secondLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${thirdLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${fourthLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${fifthLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${sixthLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                    <div className="chartLineWrapper">
                      <div
                        style={{ height: `${seventhLine}%` }}
                        className="chartOneLine"
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="chartBottomWrapper">
                  <div className="xLabel">
                    <p className="Pregular"> Mon </p>
                  </div>
                  <div className="xLabel">
                    <p className="Pregular"> Tue </p>
                  </div>
                  <div className="xLabel">
                    <p className="Pregular"> Wed </p>
                  </div>
                  <div className="xLabel">
                    <p className="Pregular"> Thu </p>
                  </div>
                  <div className="xLabel">
                    <p className="Pregular"> Fri </p>
                  </div>
                  <div className="xLabel">
                    <p className="Pregular"> Sat </p>
                  </div>
                  <div className="xLabel">
                    <p className="Pregular"> Sun </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="verticallyAlignMidStretch ratingAndSessionsWrapper">
          {reviews.length > 0 && (
            <div className="w600 mRightAuto backColElementBackgroundGray borderRadius24 yourRatingWrapper">
              <div className="mY50 w90 mXAuto">
                <h4 className="H3medium textAlignLeft mBottom12">
                  Your rating
                </h4>
                <div className="verticallyAlignMidStretch mBottom20 ratingAndSessionsSubWrapper">
                  <div className="mRight20 ratingAndSessionsSubWrapperSubb1">
                    <div className="pRight1 ratingAndSessionsSubWrapperSubb1ImgMain">
                      <div className="borderRadius50 ratingImage">
                        <div className="w100 h100 verticallyAlignMid">
                          {mentor?.photo ? (
                            <img
                              alt=""
                              className="w100 h100"
                              src={mentor.photo}
                            />
                          ) : (
                            <div className="userInitialsBig">
                              <p>{getInitials(mentor.fullName)}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="mTop10 colPrimaryGreen H3bold">
                        {avgRating}
                      </h4>
                      <p className="colPrimaryGreen Small-Labelnormal">
                        Average
                      </p>
                    </div>
                  </div>
                  <div className="w100">
                    {(() => {
                      if (avgRating >= 4) {
                        return (
                          <h6 className="Pmedium textAlignLeft mBottom10 ratingAndSessionsSubWrapperSubb2Text">
                            People
                            <mark className="colPrimaryGreen backColTransparent">
                              are loving
                            </mark>
                            your sessions!
                          </h6>
                        );
                      } else if (avgRating >= 3) {
                        return (
                          <h6 className="Pmedium textAlignLeft mBottom10">
                            People
                            <mark className="colPrimaryGreen backColTransparent">
                              are satisfied with
                            </mark>
                            your sessions!
                          </h6>
                        );
                      } else if (avgRating >= 2) {
                        return (
                          <h6 className="Pmedium textAlignLeft mBottom10">
                            People
                            <mark className="colPrimaryGreen backColTransparent">
                              are not loving
                            </mark>
                            your sessions!
                          </h6>
                        );
                      } else {
                        return (
                          <h6 className="Pmedium textAlignLeft mBottom10">
                            People
                            <mark className="colPrimaryGreen backColTransparent">
                              are hating
                            </mark>
                            your sessions!
                          </h6>
                        );
                      }
                    })()}

                    {reviews && reviews[0] && (
                      <div className="backColWhite borderRadius12">
                        <div className="w90 mXAuto mY20">
                          <div className="verticallyAlignMidJustConStart fakeForWhiteCard1">
                            <div className="pRight1 mRightAuto">
                              <div className="borderRadius50 reviewsCardImg">
                                <img
                                  alt=""
                                  className="w100 h100"
                                  src="https://i.ibb.co/fD1yxzd/img.png"
                                />
                              </div>
                            </div>
                            <div className="verticallyAlignMidStretch">
                              <RiStarFill
                                size="1.4em"
                                className="cursorPointer colPrimaryGreen mRight4"
                              />
                              <h5 className="P1medium">{reviews[0].rating}</h5>
                            </div>
                          </div>
                          <div className="verticallyAlignMidJustConStart mBottom20 ratingAndSessionsSub2Wrapper">
                            <div className="pRight1  mRight10 fakeForWhiteCard2">
                              <div className="borderRadius50">
                                {reviews[0].clientID?.photo ? (
                                  <img
                                    alt=""
                                    className="w100 h100"
                                    src={reviews[0].clientID.photo}
                                  />
                                ) : (
                                  <div className="userInitialsMedium">
                                    <p>
                                      {getInitials(
                                        reviews[0].clientID.fullName
                                      )}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mRightAuto">
                              <h5 className="Pbold breakWord nameRecommendedMentor textAlignLeft">
                                {reviews[0].clientID.fullName}
                              </h5>
                              <p className="textAlignLeft Small-Labelnormal colLabelGray">
                                {getDateForReview(reviews[0].createdAt)}
                              </p>
                            </div>
                            <div className="verticallyAlignMidStretch fakeForWhiteCard2">
                              <RiStarFill
                                size="1.4em"
                                className="cursorPointer colPrimaryGreen mRight4"
                              />
                              <h5 className="P1medium">{reviews[0].rating}</h5>
                            </div>
                          </div>
                          <p className="textAlignLeft Small-Labelnormal">
                            {reviews[0].text}
                          </p>
                          {/* <p className="textAlignLeft Small-Labelnormal">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={goToReviews}
                  style={{
                    color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                    backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                  }}
                  className="verticallyAlignMid w180 borderRadius12 borderNone Pregular colOffWhite pY10 mLeftAuto"
                >
                  See more
                </button>
              </div>
            </div>
          )}
          {lastSessions.length > 0 && (
            <div className="w600 backColElementBackgroundGray borderRadius24 lastSessionsWrapper">
              <div className="mY50 w90 mXAuto">
                <h4 className="H3medium textAlignLeft mBottom12">
                  Past Sessions
                </h4>

                {lastSessions.length === 0 ? (
                  <h3>You don't have completed sessions</h3>
                ) : (
                  lastSessions.forEach((el, i) => {
                    while (i < 3) {
                      return (
                        <MentorHomeLastSessionsComponent
                          key={i}
                          session={el}
                          parentEvent={goToSessionSingleView}
                        />
                      );
                    }
                  })
                )}

                {lastSessions.length > 0 && (
                  <button
                    onClick={() => goToSchedule("past")}
                    style={{
                      color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                      backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                    }}
                    className="verticallyAlignMid borderRadius12 borderNone Pregular colOffWhite pX30 mTop20 pY10 mLeftAuto"
                  >
                    View Past Sessions
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function getData(state) {
  return {
    company: state.auth.authData.company,
  };
}

export default connect(getData)(MentorHome1);
