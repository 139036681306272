import io from "socket.io-client";
// import { SOCKET_URL } from "config";

// const localStorageUserToken = JSON.parse(localStorage.getItem("profile"))?.token;
// console.log(localStorageUserToken);

const url =
  process.env.NODE_ENV === "production"
    ? "https://mentrx.io"
    : "http://localhost:5000";

export const socket = io(url, {
  transports: ["websocket", "polling", "flashsocket"],
  /*auth		: {
		token		: props.data.token
		token		: localStorageUserToken
	}*/
});
