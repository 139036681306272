import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiSearchLine } from "react-icons/ri";
import ButtonTagsCategoriesLandingPage from "../../components/buttons/ButtonTagsCategoriesLandingPage/ButtonTagsCategoriesLandingPage";
import LandingRecommendedMentorCards from "../../components/LandingRecommendedMentorCards/LandingRecommendedMentorCards";
import FilterMentorPopUp from "../../components/FilterMentorPopUp/FilterMentorPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import {
    getMentorsByCategories,
    getFilteredMentors,
    getFavoriteMentors,
    addFavoriteMentors,
    deleteFavoriteMentors,
    searchMentors,
} from "../../api/index.js";
import { connect } from "react-redux";
import { socket } from "../../actions/socket";

function WhatKindOfMentor(props) {
    const navigate = useNavigate();
    const company = props.data.company;

    if (!company._id || !props.user._id) {
        navigate("/LogIn");
    }

    const [showFiltersWrapper, setShowFiltersWrapper] = useState(false);

    const initialState = {
        priceMin: 0,
        priceMax: 0,
        duration: 0,
        expertise: [],
        availableNow: false,
        status: "",
    };

    const [availableMentors, setAvailableMentors] = useState([]);
    const [search, setSearch] = useState("");

    // useEffect(() => {
    // 	function getData() {
    // 		socket.emit('getAvailableMentors', null, (array) => {
    // console.log(array);
    // 			return setAvailableMentors(array);
    // 		});
    // 	}

    // 	getData();
    // },[])

    // const [formData, setFormData] = useState(initialState);
    // const formData = initialState;

    const initialMentors = {
        topRatedMentors: [],
        newMentors: [],
        allMentors: [],
    };

    const [mentors, setMentors] = useState(initialMentors);
    const [favoriteMentors, setFavoriteMentors] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        async function mentorsByCategories() {
            try {
                if (search === "") {
                    const { data } = await getMentorsByCategories();
                    setMentors((prevMentors) => data.data.mentors);
                } else {
                    const queryString = `?name=${search}`;
                    const { data } = await searchMentors(queryString);
                    setMentors((prevMentors) => data.data.mentors);
                }

                const favoriteMentorsData = await getFavoriteMentors(
                    `?populate=mentorID&clientID=${props.user._id}`
                );
                setFavoriteMentors(favoriteMentorsData.data.data.mentors);

                socket.emit("getAvailableMentors", null, (array) => {
                    return setAvailableMentors(array);
                });
            } catch (error) {
                // console.log(error);
            }
        }
        mentorsByCategories();
    }, [search, props.user._id]);

    function setValueReRender(id) {
        let array = favoriteMentors.filter((el) => el.mentorID?._id === id);

        if (array.length > 0) {
            deleteFavoriteMentors(array[0]._id);
        } else {
            addFavoriteMentors({
                clientID: props.user._id,
                mentorID: id,
            });
        }

        // updateUser(props.user._id, {favoriteMentors : array});
    }

    async function showFilters(data) {
        if (data === false) {
            setShowFiltersWrapper(false);
        } else {
            let queryString = Object.keys(data)
                .map((el, i) => {
                    let text = "";

                    if (el === "expertise" && data[el].length > 0) {
                        text += Object.keys(data[el])
                            .map((exp) => {
                                return "&" + el + "=" + data[el][exp];
                            })
                            .join("");
                    } else {
                        text += "&" + el + "=" + data[el];
                    }
                    return text;
                })
                .join("");

            queryString = "?" + queryString.slice(1);

            // console.log(queryString);

            try {
                const mentorsData = await getFilteredMentors(queryString);
                // console.log(mentorsData);

                setMentors((prevMentors) => ({
                    ...prevMentors,
                    filtered: mentorsData.data.data.users,
                }));

                setShowFiltersWrapper(false);
            } catch (error) {
                // console.log(error);
            }
        }
    }

    const goToMentorProfile = (id) => {
        navigate("/MentorProfileUserSide", {
            state: {
                id,
            },
        });
    };

    return (
        <div className="WhatKindOfMentor">
            {/* {showFiltersWrapper && (
                <div className="filterMentorPopupWrapper overflow-scroll">
                    <FilterMentorPopUp
                        initialState={initialState}
                        parentEvent={showFilters}
                    />
                </div>
            )} */}

            <div className="mTop50 mBottom70 w80 mXAuto WhatKindOfMentorSubb">
                <div className="verticallyAlignMid w100">
                    <div className="w50 mRightAuto headingMainLandingPageTop">
                        <h1 className="H1medium textAlignLeft mBottom30 w100">
                            What kind of{" "}
                            {company.whiteLabel.texts.label2MentorSingle} suits{" "}
                            <em className="colPrimaryGreen italic">you</em>,{" "}
                            {props.user.fullName.split(" ")[0]}?
                        </h1>
                        <form className="verticallyAlignMid w100 borderRadius24 boxShadowForSearchLanding mBottom30">
                            <button
                                type="submit"
                                className="searchButton backColTransparent outlineNone borderNone"
                            >
                                <RiSearchLine
                                    size="1.4em"
                                    className="searchButtonOjectFit mY14"
                                />
                            </button>
                            <input
                                type="search"
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search"
                                className="Pmedium searchField mY14 w80 borderNone outlineNone"
                            />
                            <FontAwesomeIcon
                                onClick={() => setShowFiltersWrapper(true)}
                                icon={faSlidersH}
                                size="1x"
                                className="cursorPointer"
                            />
                            {/* <FontAwesomeIcon icon={faSlidersH} size="1x" className="cursorNotAllowed"/> */}
                        </form>
                        {/* <div className="verticallyAlignMidJustConStart flexWrap buttonTagsMainLanding">
              <ButtonTagsCategoriesLandingPage text="Available Now" />
              <ButtonTagsCategoriesLandingPage text="Top Rated" />
              <ButtonTagsCategoriesLandingPage text="Recommended" />
            </div> */}
                    </div>
                    <div className="widthAndHightForLandingPageImgMain">
                        <div className="verticallyAlignMidJustConStart borderRadiusForLandingPageImg w100">
                            {company.whiteLabel.images.whatKind !== "" ? (
                                <img
                                    className="widthAndHightForLandingPageImg"
                                    alt=""
                                    src={company.whiteLabel.images.whatKind}
                                />
                            ) : (
                                <img
                                    className="widthAndHightForLandingPageImg"
                                    alt=""
                                    src="https://i.ibb.co/fD1yxzd/img.png"
                                />
                            )}
                        </div>
                    </div>
                </div>

                {search === "" ? (
                    <div className="landingHeaders">
                        <h2 className="H2medium textAlignLeft mBottom30">
                            <b>Browse by Category</b>
                        </h2>
                        <h4 className="H3medium textAlignLeft mBottom20">
                            Recommended{" "}
                            {company.whiteLabel.texts.label3MentorPlural}
                        </h4>

                        <div
                            className={
                                "verticallyAlignMidJustConStartForText mRightAuto mLeft0 forRecommendedMentors mBottom50 mXAuto " +
                                (mentors?.topRatedMentors?.length > 4
                                    ? "xScrollWrapper"
                                    : "")
                            }
                        >
                            {mentors.topRatedMentors &&
                                mentors.topRatedMentors.map((mentor, i) => {
                                    let available = false;

                                    if (
                                        availableMentors.length > 0 &&
                                        availableMentors.indexOf(mentor._id) >=
                                            0
                                    ) {
                                        available = true;
                                    }

                                    return (
                                        <LandingRecommendedMentorCards
                                            user={props.user}
                                            favorite={favoriteMentors}
                                            parentEventFavourite={
                                                setValueReRender
                                            }
                                            key={i}
                                            props={mentor}
                                            parentEvent={goToMentorProfile}
                                            available={available}
                                        />
                                    );
                                })}
                        </div>
                        <h4 className="H3medium textAlignLeft mBottom20">
                            New {company.whiteLabel.texts.label3MentorPlural}
                        </h4>
                        <div
                            className={
                                "verticallyAlignMidJustConStartForText mRightAuto mLeft0 forRecommendedMentors mBottom50 mXAuto " +
                                (mentors.newMentors &&
                                mentors.newMentors.length > 4
                                    ? "xScrollWrapper"
                                    : "")
                            }
                        >
                            {mentors.newMentors &&
                                mentors.newMentors.map((mentor, i) => {
                                    let available = false;

                                    if (
                                        availableMentors.length > 0 &&
                                        availableMentors.indexOf(mentor._id) >=
                                            0
                                    ) {
                                        available = true;
                                    }

                                    return (
                                        <LandingRecommendedMentorCards
                                            user={props.user}
                                            favorite={favoriteMentors}
                                            parentEventFavourite={
                                                setValueReRender
                                            }
                                            key={i}
                                            props={mentor}
                                            parentEvent={goToMentorProfile}
                                            available={available}
                                        />
                                    );
                                })}
                        </div>
                        <h4 className="H3medium textAlignLeft mBottom20">
                            All {company.whiteLabel.texts.label3MentorPlural}
                        </h4>
                        <div className="w100">
                            {
                                <div
                                    className={
                                        "verticallyAlignMidJustConStartForText mRightAuto mLeft0 forRecommendedMentors mBottom50 mXAuto  " +
                                        (mentors.allMentors &&
                                        mentors.allMentors.length > 4
                                            ? "xScrollWrapper"
                                            : "")
                                    }
                                >
                                    {mentors.allMentors &&
                                        mentors.allMentors.map((mentor, i) => {
                                            let available = false;

                                            if (
                                                availableMentors.length > 0 &&
                                                availableMentors.indexOf(
                                                    mentor._id
                                                ) >= 0
                                            ) {
                                                available = true;
                                            }

                                            return (
                                                <LandingRecommendedMentorCards
                                                    user={props.user}
                                                    favorite={favoriteMentors}
                                                    parentEventFavourite={
                                                        setValueReRender
                                                    }
                                                    key={i}
                                                    props={mentor}
                                                    parentEvent={
                                                        goToMentorProfile
                                                    }
                                                    available={available}
                                                />
                                            );
                                        })}
                                </div>
                            }
                        </div>
                    </div>
                ) : (
                    <div className="verticallyAlignMidJustConStart flexWrap w100 mTop30">
                        {mentors.length > 0 ? (
                            mentors.map((mentor, i) => {
                                let available = false;

                                if (
                                    availableMentors.length > 0 &&
                                    availableMentors.indexOf(
                                        mentor.mentorID._id
                                    ) >= 0
                                ) {
                                    available = true;
                                }

                                return (
                                    <div className="" key={i}>
                                        <LandingRecommendedMentorCards
                                            user={props.user}
                                            favorite={availableMentors}
                                            parentEventFavourite={
                                                setValueReRender
                                            }
                                            props={mentor}
                                            parentEvent={goToMentorProfile}
                                            available={available}
                                        />
                                    </div>
                                );
                            })
                        ) : (
                            <div>
                                <h1>No mentors...!</h1>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function getUser(state) {
    return {
        data: state.auth.authData,
        user: state.auth.authData?.data.user,
    };
}

export default connect(getUser)(WhatKindOfMentor);
