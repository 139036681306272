// import { RiCheckboxCircleLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { finishSession, getSessions } from "../../api/index.js";
import Moment from "moment";
import { socket } from "../../actions/socket.js";
import { Datepicker, setOptions } from "@mobiscroll/react";
import { connect } from "react-redux";

const BigScheduleCalendar = ({
  mentor,
  service,
  parentEvent,
  type,
  typeID,
  dataCompany,
}) => {
  let clientID = dataCompany.data.user._id;

  setOptions({
    theme: "ios",
    themeVariant: "light",
  });

  // const [sessions, setSessions] 						= useState([]);
  const [sessionsInTimeStamp, setSessionsInTimeStamp] = useState([]);
  const [finalSessions, setFinalSessions] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [seeAllSessions, setSeeAllSessions] = useState(false);

  const company = dataCompany.company;
  const charityCompany = company.charity;

  const [availableMentors, setAvailableMentors] = useState([]);

  useEffect(() => {
    function getData() {
      socket.emit("getAvailableMentors", null, (array) => {
        return setAvailableMentors(array);
      });
    }

    getData();
  }, []);

  useEffect(() => {
    async function getSessionData() {
      if (mentor && service) {
        let today = Moment().format("LLLL");
        today = new Date(today).toISOString();

        //We need all booked sessions, from both client and mentor in order not to overlap some
        let queryString = `?filter={"date" : {"$gte" : "${today}"}, "$or":[{"mentorID":"${mentor._id}"},{"clientID":"${clientID}"}]}&populate=serviceID&fields=date,serviceID.duration`;

        try {
          const { data } = await getSessions(queryString);
          // setSessions(data.data.sessions);

          // console.log(mentor)
          // console.log(data)

          let sessionsInTimeStampVar = data.data.sessions.map((el) => {
            let from = Moment(el.date).format("x") * 1;
            let to = from + el.serviceID.duration * 60 * 1000;
            return { from, to };
          });

          setSessionsInTimeStamp(sessionsInTimeStampVar);
        } catch (error) {
          // console.log(error);
        }
      }
    }
    getSessionData();
  }, [mentor, service, selectedDate, clientID]);

  function get12hTime(timeInStamp) {
    return Moment(timeInStamp).format("LT");
  }

  // function getDateFormat(date) {
  //   return `${Moment(date).format("dddd").toUpperCase()}, ${Moment(date).format(
  //     "MMM DD"
  //   )}`;
  // }

  const navigate = useNavigate();

  async function goToSessionPage(session) {
    if (type && type === "reschedule") {
      let updatedDate = Moment(
        session.date + ", " + get12hTime(session.from)
      ).format();

      const sData = await finishSession(typeID, {
        date: updatedDate,
      });

      if (sData.data.data.session) {
        parentEvent(false);
      }
    } else {
      session.date = session.date + ", " + get12hTime(session.from);

      navigate("/ClientConfirmSession", {
        state: {
          session,
          mentor,
          service,
        },
      });
    }
  }

  useEffect(() => {
    function goThroughAvailability() {
      let thisMomentInX = Moment().format("x");

      let selectedMomentInX;
      if (!selectedDate) {
        selectedMomentInX = Moment().format("x");
      } else {
        selectedMomentInX = Moment(selectedDate).format("x");
      }

      let availabileSessions = [];
      let numberOfDaysChecked = 0;

      while (availabileSessions.length < 10 && numberOfDaysChecked < 100) {
        //translate mentor's availability to unix format
        let thisDateDayInWeek = Moment.utc(
          selectedMomentInX * 1 + numberOfDaysChecked * 24 * 60 * 60 * 1000
        ).format("dddd");
        // let thisDateExcludedFormat = Moment.utc(
        //   selectedMomentInX * 1 + numberOfDaysChecked * 24 * 60 * 60 * 1000
        // ).format("YYYY/MM/DD");
        let thisDateMidnightTimeStamp =
          Moment(selectedMomentInX * 1)
            .add(numberOfDaysChecked, "days")
            .utc()
            .startOf("day")
            .format("x") * 1;

        let thisDayAvailabilityFromDB = mentor?.availability?.filter(
          (el) => el.day === thisDateDayInWeek
        )[0];
        let thisDayAvailabilitiesInUnix = [];

        if (!thisDayAvailabilityFromDB?.selected) {
          numberOfDaysChecked++;
          continue;
        } else {
          thisDayAvailabilitiesInUnix = thisDayAvailabilityFromDB.time.map(
            (el) => {
              //Unix time stamp that mentor had set is some date in the past (in the moment he set it)

              let midnightTimeStampOfTheDateFromDBFrom =
                Moment(el.from * 1)
                  .utc()
                  .startOf("day")
                  .format("x") * 1;
              let midnightTimeStampOfTheDateFromDBTo =
                Moment(el.to * 1)
                  .utc()
                  .startOf("day")
                  .format("x") * 1;

              let fromInUnix =
                el.from * 1 - midnightTimeStampOfTheDateFromDBFrom;
              let toInUnix = el.to * 1 - midnightTimeStampOfTheDateFromDBTo;

              return {
                from: thisDateMidnightTimeStamp + fromInUnix,
                to: thisDateMidnightTimeStamp + toInUnix,
              };
            }
          );
        }

        let serviceDurationMS = service.duration * 60 * 1000;

        let x = 0;

        thisDayAvailabilitiesInUnix.forEach((availabilityPeriodInADay) => {
          while (
            availabilityPeriodInADay.from +
              x * serviceDurationMS +
              serviceDurationMS -
              1 <=
            availabilityPeriodInADay.to
          ) {
            let potentialTimeStart =
              availabilityPeriodInADay.from + x * serviceDurationMS;
            let potentialTimeEnd =
              availabilityPeriodInADay.from +
              x * serviceDurationMS +
              serviceDurationMS;

            let possible = true;
            sessionsInTimeStamp.forEach((el) => {
              if (potentialTimeEnd <= el.from || potentialTimeStart >= el.to) {
              } else {
                // console.log("FALSE", el);
                possible = false;
              }
            });

            if (mentor.availabilityExcludedDates.selected) {
              mentor.availabilityExcludedDates.dates.forEach((exDate) => {
                let exDateBeginning = Moment(parseInt(exDate.from)).format(
                  "MM/DD/YYYY"
                );

                if (
                  Moment(potentialTimeStart).format("MM/DD/YYYY") ===
                  exDateBeginning
                ) {
                  possible = false;
                }

                if (
                  Moment(potentialTimeEnd).format("MM/DD/YYYY") ===
                  exDateBeginning
                ) {
                  possible = false;
                }

                // Allow session which ends on the begiignig of a excluded date (midnight)
                if (potentialTimeEnd * 1 === exDate.from * 1) {
                  possible = true;
                }
              });
            }

            let selectedDateStart = selectedMomentInX * 1;
            let selectedDateEnd = selectedMomentInX * 1 + 24 * 60 * 60 * 1000;

            if (selectedDate) {
              if (
                selectedDateStart <= potentialTimeStart &&
                potentialTimeEnd < selectedDateEnd
              ) {
              } else {
                possible = false;
              }
            }

            if (possible) {
              //Date that we get from the DB is actually some date in past from the moment it was set by mentor

              let midnightTimeStampOfTheDateFromDBFrom =
                Moment(potentialTimeStart * 1)
                  .utc()
                  .startOf("day")
                  .format("x") * 1;
              let milisecondsAfterMidnigh =
                potentialTimeStart * 1 - midnightTimeStampOfTheDateFromDBFrom;

              //let localDate = Moment(thisDateMidnightTimeStamp + milisecondsAfterMidnigh).format('dddd MMM DD YYYY');

              let minimumTimeToBookInFuture = 12 * 60 * 60 * 1000;
              // let minimumTimeToBookInFuture = 0;

              //Do not allow moments from the past, or at least 12h in future(or this value will be whitelabeled...)
              if (
                thisMomentInX * 1 + minimumTimeToBookInFuture <
                thisDateMidnightTimeStamp + milisecondsAfterMidnigh
              ) {
                availabileSessions.push({
                  date: Moment(potentialTimeStart).format("dddd MMM DD YYYY"),
                  from: potentialTimeStart,
                  to: potentialTimeEnd,
                });
              }
            }
            x++;
          }
        });

        numberOfDaysChecked++;
      }

      setFinalSessions(availabileSessions);
    }

    goThroughAvailability();
  }, [
    sessionsInTimeStamp,
    mentor?.availability,
    mentor?.availabilityExcludedDates?.dates,
    mentor?.availabilityExcludedDates?.selected,
    selectedDate,
    service.duration,
  ]);

  function instantSession() {
    let dates = Moment().format("H:m").split(":");
    let from = parseInt(dates[0]) * 60 + parseInt(dates[1]) + 5;
    let to = from + service.duration;

    navigate("/ClientConfirmSession", {
      state: {
        session: {
          date: Moment(new Date()).add(5, "minutes").format("MM/DD/YYYY, LT"),
          from,
          to,
        },
        mentor,
        service,
      },
    });
  }

  let weekDays = "";

  mentor.availability.forEach((el) => {
    if (!el.selected) {
      if (weekDays === "") {
        weekDays += el.day.substring(0, 2).toUpperCase();
      } else {
        weekDays += `,${el.day.substring(0, 2).toUpperCase()}`;
      }
    }
  });

  const props = {
    placeholder: "Please Select...",
    className:
      "Pregular pLeft8 textAlignLeft verticallyAlignMidJustConStart backColWhite borderNone pY14 borderRadius12 mRightAuto",
  };

  let excludedDates = [
    {
      recurring: {
        repeat: "weekly",
        weekDays: weekDays,
      },
    },
  ];

  if (mentor?.availabilityExcludedDates?.selected) {
    mentor?.availabilityExcludedDates?.dates.forEach((el) => {
      excludedDates.push(el);
    });
  }

  return (
    <div className="backColElementBackgroundGray bigScheduleCalendarMain w400 borderRadius24">
      {availableMentors.length > 0 &&
        availableMentors.indexOf(mentor._id) >= 0 && (
          <div className="w90 mXAuto mTop10 pBottom10">
            <button
              onClick={instantSession}
              style={{
                color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
              }}
              className="verticallyAlignMid borderRadius12 mTop50 borderNone bigScheduleCalendarViewAll P1bold w340 pTop10 pBottom10 mXAuto"
            >
              Instant Session
            </button>
          </div>
        )}

      <div className="w90 mXAuto mTop40 pBottom50">
        <div className="verticallyAlignMidJustConStart mBottom40 bigScheduleCalendarMainSubb1">
          <Datepicker
            controls={["calendar"]}
            // touchUi={true}
            inputComponent="input"
            inputProps={props}
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.valueText);
              setSeeAllSessions(false);
            }}
            min={Moment().format("MM/DD/YYYY")}
            // invalid={excludedDates}
          />

          {/* <input type="date" onChange={(e) => setSelectedDate(e.target.value)} min={new Date().toISOString().split('T')[0]} className="Pregular pLeft8 textAlignLeft verticallyAlignMidJustConStart backColWhite borderNone pY14 borderRadius12 mRightAuto" /> */}
          <h5 style={charityCompany ? { opacity: 0 } : {}} className="P1medium">
            {/* ${charityCompany ? "0" : service.rate}/hour */}
            ${charityCompany ? "0" : Math.round(service.rate / 60 * 100) / 100}/minute
          </h5>
        </div>

        {finalSessions &&
          finalSessions.map((sess, i) => {
            if (!seeAllSessions && i > 2) {
              return;
            }
            let date = sess.date;

            return (
              <div key={i}>
                <div className="verticallyAlignMidJustConStart bigScheduleCalendarMainSubb2">
                  <div>
                    <div className="Small-Labelnormal textAlignLeft">
                      {date}
                    </div>
                    <h6 className="Pmedium textAlignLeft">
                      {get12hTime(sess.from)} - {get12hTime(sess.to)}
                    </h6>
                  </div>
                  <button
                    onClick={() => goToSessionPage(sess)}
                    style={{
                      color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
                      backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
                    }}
                    className="verticallyAlignMid borderRadius12 borderNone P1regular pX30 pY10 mLeftAuto"
                  >
                    Select
                  </button>
                </div>
                <div className="borderGrayTopInput w100 mY20"></div>
              </div>
            );
          })}

        {finalSessions && finalSessions.length === 0 && (
          <div className="verticallyAlignMidJustConStart bigScheduleCalendarMainSubb2">
            <h2>No available sessions for this day...!</h2>
          </div>
        )}

        {seeAllSessions && finalSessions && finalSessions.length > 3 && (
          <button
            style={{
              color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
              backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
            }}
            onClick={() => setSeeAllSessions(false)}
            className="verticallyAlignMid borderRadius12 mTop50 borderNone bigScheduleCalendarViewAll P1bold w340 pTop10 pBottom10 mXAuto"
          >
            View Less
          </button>
        )}
        {!seeAllSessions && finalSessions && finalSessions.length > 3 && (
          <button
            onClick={() => setSeeAllSessions(true)}
            style={{
              color: `${company?.whiteLabel?.color?.buttons?.primaryText}`,
              backgroundImage: `linear-gradient(to right top, ${company?.whiteLabel?.color?.buttons?.primary?.from}, ${company?.whiteLabel?.color?.buttons?.primary?.to})`,
            }}
            className="verticallyAlignMid borderRadius12 mTop50 borderNone bigScheduleCalendarViewAll P1bold w340 pTop10 pBottom10 mXAuto"
          >
            View All
          </button>
        )}
      </div>
    </div>
  );
};

function getData(state) {
  return {
    dataCompany: state.auth.authData,
  };
}

export default connect(getData)(BigScheduleCalendar);
