import { connect } from 'react-redux'

const ConifrmSessionMessageYourMentorComponent = (props) => {

   const company = props.data.company;

   return (
      	<div className="conifrmSessionMessageYourMentorComponentMain">
            <h2 className="H2regular textAlignLeft mTop50 mBottom12">Message your {company.whiteLabel.texts.label2MentorSingle}</h2>
            <p className="Pmedium textAlignLeft mBottom12">Any special requests</p>
            <textarea rows="8" onChange={(e) => props.parentEvent(e.target.value)} className="conifrmSessionMessageYourMentorComponentMainText pX20 pTop10 verticallyAlignMidJustConStart colLabelGray w100 Pregular textAlignLeft mBottom40 borderRadius24 borderNone backColInput">
            </textarea>
        </div>
   );
}

function getUser(state) {
    return {
        data : state.auth.authData
    }
}

export default connect(getUser)(ConifrmSessionMessageYourMentorComponent);
