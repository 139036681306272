import axios from 'axios';

//  const API = axios.create({ baseURL: 'https://mentorxwl.herokuapp.com/api/v1' });
const baseURL   = process.env.NODE_ENV === 'production' ? 'https://mentrx.io/api/v1' : 'http://localhost:5000/api/v1';
const API       = axios.create({ baseURL: baseURL });

API.interceptors.request.use((req) => {

    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }

    return req;
});

// axios global error handler with intercetpors

// export const deletePost = (id) => API.delete(`/posts/${id}`);

export const signIn                     = (formData) => API.post('/auth/signIn', formData);

export const signUp                     = (formData) => API.post('/auth/signUp', formData);
export const setPassword                = (formData) => API.patch('/auth/setupPassword', formData);
export const updatePassword             = (formData) => API.patch('/auth/updatePassword', formData);

export const socialSignUp               = (formData) => API.post('/auth/socialSignUp', formData);

export const sendForgotPasswordEmail    = (formData) => API.post('/auth/forgotPassword', formData);
export const saveResetedPassword        = (formData) => API.patch('/auth/resetPassword', formData);

export const userGoogleLogin        = (formData) => API.post('/auth/googleLogin', formData);

export const getAllUsers            = (queryString = "") => API.get('/users' + queryString);
// export const getUser                = (id) => API.get('/users/' + id);
export const clientOnboarding       = (formData) => API.post('/users/clientOnboarding', formData);
export const mentorOnboarding       = (formData) => API.post('/users/mentorOnboarding', formData);
export const mentorDataPatch        = (formData) => API.patch('/users/updateMe', formData);
export const getMentorsByCategories = () => API.get('/users/mentors/recommended');
export const searchMentors          = (queryString) => API.get('/users/mentors/search' + queryString);
export const getRecomendedMentors   = (queryString) => API.get('/users' + queryString);
export const getFilteredMentors     = (queryString) => API.get('/users' + queryString);

export const updateUser             = (id, formData) => API.patch('/users/' + id, formData)
export const uploadProfilePhoto     = (id, formData) => API.post(`/users/${id}`, formData)

export const connectSocial          = (id, formData) => API.post(`/users/${id}/connectSocial`, formData);

export const uploadCV               = (id, formData) => API.post(`/users/${id}/cv`, formData)

// export const getService             = (id) => API.get('/services/' + id);
export const mentorCreatesService   = (formData) => API.post('/services', formData);

export const mentorEditService      = (id, formData) => API.patch('/services/' + id, formData);

// export const getSession                 = (id) => API.get(`/sessions?id=${id}`);

export const clientBookSession          = (formData) => API.post('/sessions', formData);
// export const getMentorSessions          = (mentorID) => API.get(`/sessions/${mentorID}`);
// export const getMentorClientSessions    = (mentorID, clientID) => API.get(`/sessions/${mentorID}/${clientID}`);
// export const getUserSessions            = (id) => API.get(`/sessions/getSessions/user/${id}`);

// export const getAllPlans                = () => API.get('/company/plans');
export const getAllCategories           = (queryString)     => API.get('/categories' + queryString);
export const updateCategories           = (id, formData)    => API.patch('/categories/' + id, formData);
export const addCategory                = (formData)        => API.post('/categories', formData);

export const getAllAdditionalCategories = (queryString)     => API.get('/additionalCategories' + queryString);
export const updateAdditionalCategories = (id, formData)    => API.patch('/additionalCategories/' + id, formData);
export const addAdditionalCategory      = (formData)        => API.post('/additionalCategories', formData);

// export const getMentorReviews = (id) => API.get('/reviews/' + id);

export const getNotifications       = (queryString)             => API.get('/notifications' + queryString);
export const createNotification     = (formData)                => API.post('/notifications/', formData);
export const editNotification       = (id, formData)            => API.patch(`/notifications/${id}`, formData);
export const setAllNotificationsSeen = ()                       => API.patch(`/notifications`, {seen: true});
export const setNotificationRead    = (id)                      => API.patch(`/notifications/${id}`, {read: true});

export const getUsers               = (queryString)             => API.get('/users' + queryString);
export const getUser                = (id, queryString = '')    => API.get(`/users/${id + queryString}`);

export const getServices            = (queryString)             => API.get('/services' + queryString);
export const getService             = (id, queryString = '')    => API.get(`/services/${id + queryString}`);

export const getSessions            = (queryString)             => API.get('/sessions' + queryString);
export const getSession             = (id, queryString = '')    => API.get(`/sessions/${id + queryString}`);
export const finishSession          = (id, formData)            => API.patch(`/sessions/${id}`, formData);

export const getReviews             = (queryString)             => API.get('/reviews' + queryString);
export const getReview              = (id, queryString = '')    => API.get(`/reviews/${id + queryString}`);
export const addReview              = (formData)                => API.post('/reviews', formData)

export const getNotes               = (queryString)             => API.get('/notes' + queryString);
export const getNote                = (id, queryString = '')    => API.get(`/notes/${id + queryString}`);
export const createNote             = (formData)                => API.post('/notes', formData);
export const updateNote             = (id, formData)            => API.patch(`/notes/${id}`, formData);

export const getMessages            = (queryString)             => API.get('/messages' + queryString);
export const getInbox               = ()                        => API.get('/messages/inbox');
export const getMessage             = (id, queryString = '')    => API.get(`/messages/${id + queryString}`);
export const createMessage          = (formData)                => API.post('/messages', formData);
export const updateMessages         = (formData)                => API.patch('/messages', formData);

export const getFavoriteMentors     = (queryString)             => API.get('/favoriteMentors' + queryString);
export const addFavoriteMentors     = (formData)                => API.post('/favoriteMentors', formData);
export const deleteFavoriteMentors  = (id)                      => API.delete('/favoriteMentors/' + id);

export const getAllCompanies            = (queryString = '')            => API.get('/company' + queryString);
export const addCompany                 = (formData)                    => API.post('/company', formData)
export const getCompany                 = (id)                          => API.get('/company/' + id)
export const updateCompany              = (id, formData)                => API.patch('/company/' + id, formData)
export const uploadCompanyLogo          = (id, img)                     => API.post('/company/' + id, img)
export const uploadCompanyImages        = (id, formData)                => API.post('/company/images/' + id, formData)
export const getBusinessInitialData     = (queryString = '')            => API.get('/company/business/'+ queryString);
export const getBusinessParameterData   = (parameter, queryString = '') => API.get(`/company/business/${parameter}`+ queryString);

export const createStripePaymentIntent  = (formData)                    => API.post('/stripe', formData);

// income
// revenue
// sessions
// clients
// mentors