// import { useEffect } from "react";
// import jwt_decode from "jwt-decode";

function Page404() {
    // function handleCallbackResponse(response) {
    // 	const user = jwt_decode(response.credential)
    // 	console.log(user);
    // }

    // useEffect(() => {
    // 	/* global google */

    // 	google.accounts.id.initialize({
    // 	  	// client_id   : "567198460390-ga96uugu7b8hnoi04get5nvevr4oomlc.apps.googleusercontent.com",
    // 		// client_id: "567198460390-oo360r7n7jsiruap9nilpe4ooohqkhfs.apps.googleusercontent.com",
    // 	  	client_id: "567198460390-eqfp1i18tf1lbphu7h50a9c7h547s1rs.apps.googleusercontent.com",
    // 	  	callback: handleCallbackResponse,
    // 	});

    // 	google.accounts.id.renderButton(document.getElementById("signInDiv"), {
    // 	  	theme: "outline",
    // 	  	size: "large",
    // 	});

    // 	google.accounts.id.prompt();
    //   }, []);

    return (
        <div className="Page404 mY50">
            <h1 className="mY20"> Oops 404! </h1>
            <h2> That Page Can't Be Found </h2>
            {/* <div id="signInDiv"></div> */}
        </div>
    );
}

export default Page404;
